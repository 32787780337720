import React, { useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import PSModal from '../../components/modal';
import SyllabusForm from '../../components/forms/syllabusForm';
import Button from '../../components/buttons/linkButton';
import { Container, Col, Row } from 'react-bootstrap';
import Community from '../../assets/images/community.png';
import ReactGA from 'react-ga'
import Curriculum from '../../components/curriculum'; 
import qa from '../../assets/images/qa.png'
import logo from '../../assets/images/parsity-logo-black.png'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Navbar from '../../components/navbars/courseLandingNavbar';

const PrepContainer = styled.div`
  display: block;
  background: none;
  background-image: url(${Community});
  background-size: cover;
  background-position: center center;
`

const HeroContainer = styled(Container)`
  padding-top: 130px;
  max-width: 1000px;
  height: 500px;
  .logo {
    max-width: 200px;
    padding-bottom: 50px;
  }
  .col-hero-text {
    text-align: center;
    padding-left: 40px;
    h1 {
      padding-top: 120px;
      padding-bottom: 20px;
      color: var(--white);
      text-align: center;
      font-size: 70px;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      } 
    }
    .subtext {
      padding: 0px 0 20px 0;
      color: var(--white);
      text-align: center;
    }
    .cta-syllabus {
      margin-left: 20px;
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  } 
`


const AboutSection = styled.section`
  padding-bottom: 80px;
  .support-row {
    margin-top: 80px;
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .support-text {
      font-size: 16px;
    }
  }
  p {
    padding-top: 20px;
    text-align: center;
  }
  .features-title {
    padding-top: 40px;
    text-align: center;
  }
  .btn-apply-curriculum {
    margin-bottom: 40px;
    margin-top: 40px;
  }
`

const GetStartedBox = styled.div`
  border: 4px solid var(--black);
  background: #fff;
  margin-top: 40px;
  box-shadow: -15px 15px var(--black);
`

const AboutHero = styled(Container)`
  .about-left {
    p {
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 0px;
      padding-right: 50px;
    }
    .subtext-launch {
      font-size: 18px;
      font-style: italic;
    }
  }
  .about-right {
    padding: 0;
    max-width: 1000px;
    img {
      box-shadow: -15px 15px var(--black);
      border: 5px solid var(--black);
    }
  }
`

const GetStartedSection = styled.section`
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: var(--blue);
  p {
    color: #fff;
    padding-top: 20px;
    text-align: center;
  }
  .get-started-title {
    text-align: center;
    color: var(--white);
  }
  .btn-apply-curriculum {
    margin-top: 40px;
  }

  .steps-list {
    line-height: 1.85714;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
  }

  .step {
    padding: 41px 55px 51px;
    width: 33%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
  }

  .step-text {
    color: var(--black);
    font-size: 14px;
  }

  .step-title {
    text-align: center;
  }

  .step {
    border-right: 1px solid grey;
    margin-top: 20px;
  }

  .step:nth-child(3) {
    border: none
  }
`

const Index = ({location}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => setShow(true);

  const fireGTMEvent = () => {
    ReactGA.event({
      category: 'CTA - Button',
      action: 'Click',
      label: 'waitlist',
    })
  };

  return (
    <Layout>
      <Navbar location={location}/>
      <PrepContainer>
        <PSModal handleClose={handleClose} handleShow={handleShow} show={show}>
          <SyllabusForm close={handleClose} />
        </PSModal>
        <HeroContainer>
          <Row>
            <Col className='col-hero-text' md={12}>
              <h1>Community</h1>
            </Col>
          </Row>
        </HeroContainer>
      </PrepContainer> 
      <GetStartedSection>
        <Container>
          <Row>
            <Col md={12}>
              <h2 className='get-started-title'>Connect with the Parsity Community</h2>
              <GetStartedBox>
                <div>
                <ul class="size-14 steps-list">
                  <li class="step">
                    <h3 className='step-title'>Online Workshops</h3>
                    <p className='step-text'>Attend an upcoming event to get started with coding or learn more about Parsity.</p>
                    <a href='/community/events/'>
                      <Button link={true} text={'RSVP for Workshops'} />
                    </a>
                  </li>
                  <li class="step">
                    <h3 className='step-title'>Parsity Slack</h3>
                    <p className='step-text'>Get to know others learning to code and ask questions from experienced mentors.</p>
                    <a href='/links/slack/' style={ {'text-align': 'center'}}>
                      <Button link={true} text={'Join Slack'} />
                    </a>
                  </li>
                  <li class="step">
                    <h3 className='step-title'>Parsity Hacker League</h3>
                    <p className='step-text'>Apply for our members only community of developers who meet regularly to help one another advance their careers.</p>
                    <a href='/community/hacker-league/'>
                      <Button link={true} text={'Join Hacker League'} />
                    </a>
                  </li>
                </ul>
              </div>
              </GetStartedBox> 
            </Col>
          </Row>
        </Container>
      </GetStartedSection>
    </Layout>
  )
}

export default Index
